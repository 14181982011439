import React from 'react'
import Intro from '../components/intro'

const palaa_alkuun = {
   value: 10,
   label: "Palaa alkuun",
   trigger: "hank-helsinki-question-3"
}

const Introduction = [
  {
    id: 'intro',
    component: <Intro />,
    delay: 0,
    trigger: 'hank-helsinki-question-1'
  },
  {
    id: "hank-helsinki-question-1",
    message: "Hei Olen Hank Helsinki. Autan sinua hankintoihin liittyvissä kysymyksissä (tilaamiseen liittyvät kysymykset voit ratkaista täällä:....)",
    trigger: "hank-helsinki-question-2"
  },
  {
    id: "hank-helsinki-question-2",
    message: "Mitä olet hankkimassa?",
    trigger: "hank-helsinki-hank-helsinki-question-2-choices"
  },
  {
    id: 'hank-helsinki-hank-helsinki-question-2-choices',
    user: true,
    trigger: 'hank-helsinki-question-3',
  },
  {
    id: "hank-helsinki-question-3",
    message: "Onko kyse yli 10 000 euron hankinnasta?",
    trigger: "hank-helsinki-hank-helsinki-question-3-choices"
  },
  {
    id: "hank-helsinki-hank-helsinki-question-3-choices",
    metadata: {
       parent: "hank-helsinki-question-3",
       detail: {
          en: {
             detail: "",
             title: ""
          },
          fi: {
             detail: "Hankinnan arvoksi lasketaan...",
             title: "Onko kyse yli 10 000 euron hankinnasta?"
          }
       }
    },
    options: [
       {
          value:4,
          label:"Ei",
          trigger: "hank-helsinki-question-4"
       },
       {
          value:5,
          label:"Kyllä",
          trigger: "hank-helsinki-question-11"
       }
    ]
  },
  {
    id: "hank-helsinki-question-4",
    message: "Onko kyseessä puitejärjestelyn sisäisestä hankinnasta?",
    trigger: "hank-helsinki-hank-helsinki-question-4-choices"
  },
  {
    id: "hank-helsinki-hank-helsinki-question-4-choices",
    metadata: {
       parent: "hank-helsinki-question-4",
       detail: {
          en: {
             detail: "",
             title: ""
          },
          fi: {
             detail: "",
             title: "Onko kyseessä puitejärjestelyn sisäisestä hankinnasta?"
          }
       }
    },
    options: [
       {
          value:7,
          label:"Ei",
          trigger: "hank-helsinki-question-5"
       },
       {
          value:8,
          label:"Kyllä",
          trigger: "hank-helsinki-question-6"
       }
    ]
  },
  {
    id: "hank-helsinki-question-5",
    message: "Jos sinulla on riittävät hankintavaltuudet, voit toteuttaa hankinnan hyväksymällä esim. saamasi tarjouksen sähköpostissa.",
    trigger: "hank-helsinki-hank-helsinki-question-5-choices"
  },
  {
    id: "hank-helsinki-hank-helsinki-question-5-choices",
    metadata: {
       parent: "hank-helsinki-question-5",
       detail: {
          en: {
             detail: "",
             title: ""
          },
          fi: {
             detail: "Hankintavaltuuksien puitteissa pöytäkirjapäätöksenä, lomakepäätöksenä, sähköisenä päätöksenä tai muuna kirjallisena päätöksenä kuten hyväksymällä tilaus-asiakirja. Hankintavaltuuksilla tarkoitetaaan....",
             title: "Jos sinulla on riittävät hankintavaltuudet, voit toteuttaa hankinnan hyväksymällä esim. saamasi tarjouksen sähköpostissa."
          }
       }
    },
    options: [
      palaa_alkuun
    ]
  },
  {
    id: "hank-helsinki-question-6",
    message: "Onko kyse etusijajärjestyksestä?",
    trigger: "hank-helsinki-hank-helsinki-question-6-choices"
  },
  {
    id: "hank-helsinki-hank-helsinki-question-6-choices",
    metadata: {
       parent: "hank-helsinki-question-6",
       detail: {
          en: {
             detail: "",
             title: ""
          },
          fi: {
             detail: "Etusijjärjestyksellä tarkoitetaan...",
             title: "Onko kyse etusijajärjestyksestä ?"
          }
       }
    },
    options: [
       {
          value:11,
          label:"Ei",
          trigger: "hank-helsinki-question-7"
       },
       {
          value:12,
          label:"Kyllä",
          trigger: "hank-helsinki-question-8"
       }
    ]
  },
  {
    id: "hank-helsinki-question-7",
    message: "Jos sinulla on riittävät hankintavaltuudet, voit toteuttaa hankinnan hyväksymällä esim. saamasi tarjouksen sähköpostissa ",
    trigger: "hank-helsinki-hank-helsinki-question-7-choices"
  },
  {
    id: "hank-helsinki-hank-helsinki-question-7-choices",
    metadata: {
       parent: "hank-helsinki-question-7",
       detail: {
          en: {
             detail: "",
             title: ""
          },
          fi: {
             detail: "Hankintavaltuuksien puitteissa pöytäkirjapäätöksenä, lomakepäätöksenä, sähköisenä päätöksenä tai muuna kirjallisena päätöksenä kuten hyväksymällä tilaus-asiakirja. Hankintavaltuuksilla tarkoitetaan...",
             title: "Jos sinulla on riittävät hankintavaltuudet, voit toteuttaa hankinnan hyväksymällä esim. saamasi tarjouksen sähköpostissa "
          }
       }
    },
    options: [
       palaa_alkuun
    ]
  },
  {
    id: "hank-helsinki-question-8",
    message: "Tee hankinta ensisijaisesti sopimukseen kirjatun etusijajärjestyksen mukaisesti. Koska hankinnan arvo on kynnysarvot alittava, voit tehdä myös poikkeuksen. Poikkeuksesta tulee tehdä perusteltu päätös. Päätökseen voi hakea muutosta.",
    trigger: "hank-helsinki-hank-helsinki-question-8-choices"
  },
  {
    id: "hank-helsinki-hank-helsinki-question-8-choices",
    metadata: {
       parent: "hank-helsinki-question-8",
       detail: {
          en: {
             detail: "",
             title: ""
          },
          fi: {
             detail: "",
             title: "Kun hankinta alittaa kansallisen kynnysarvon, hankinta voidaan tehdä myös etusijajärjestyksestä poiketen parhaaksi katsotulta puitejärjestelyyn valitulta toimittajalta. Tällöin kyse ei enää ole täytäntöönpanosta, vaan päätöksestä, joka tulee perustella (ja perustelu kirjata). Kaupungin päätöksentekoa ohjeet (ml. pienhankintaohjeet, talousarvion noudattamisohje) ja soveltuva lainsäädäntö (hallintolaki, kuntalaki ym.) on päätöstä tehtäessä otettava huomioon. Päätöksestä tulee sen muodosta riippumatta ilmetä peruste etusijajärjestyksestä poikkeamiseen. Päätökseen voidaan sen muodosta riippumatta hakea muutosta."
          }
       }
    },
    options: [
      {
         value: 15,
         label: "Teen etusijajärjestyksen mukaisen hankinnan",
         trigger: "hank-helsinki-question-3"
      },
      {
        value:16,
        label:"Teen poikkeuksen",
        trigger: "hank-helsinki-toimialat"
      }
    ]
  },
  {
    id: "hank-helsinki-toimialat",
    message: "Minkä toimialan hankinnasta on kysymys?",
    trigger: "hank-helsinki-hank-helsinki-toimialat-choices"
  },
  {
    id: "hank-helsinki-hank-helsinki-toimialat-choices",
    metadata: {
       parent: "hank-helsinki-toimialat",
       detail: {
          en: {
             detail: "",
             title: ""
          },
          fi: {
             detail: "",
             title: "Minkä toimialan hankinnasta on kysymys?"
          }
       }
    },
    options: [
       {
          value:17,
          label:"Kulttuuri ja vapaa-aika",
          trigger: "hank-helsinki-question-10"
       }
    ]
  },
  {
    id: "hank-helsinki-question-10",
    message: "Linkki kulttuuri- ja vapaa-aika toimialan muutoksenhakuohjeisiin",
    trigger: "hank-helsinki-hank-helsinki-question-10-choices"
  },
  {
    id: "hank-helsinki-hank-helsinki-question-10-choices",
    metadata: {
       parent: "hank-helsinki-question-10",
       detail: {
          en: {
             detail: "",
             title: ""
          },
          fi: {
             detail: "",
             title: "Sosiaali-ja terveystoimialan muutosksenhakuohjeet (linkki)"
          }
       }
    },
    options: [
       palaa_alkuun
    ]
  },
  {
    id: "hank-helsinki-question-11",
    message: "Onko kyseessä puitejärjestelyn sisäisestä hankinnasta?",
    trigger: "hank-helsinki-hank-helsinki-question-11-choices"
  },
  {
    id: "hank-helsinki-hank-helsinki-question-11-choices",
    metadata: {
       parent: "hank-helsinki-question-11",
       detail: {
          en: {
             detail: "",
             title: ""
          },
          fi: {
             detail: "",
             title: "Onko kyseessä puitejärjestelyn sisäisestä hankinnasta?"
          }
       }
    },
    options: [
       {
          value:20,
          label:"Ei",
          trigger: "hank-helsinki-question-12"
       },
       {
          value:21,
          label:"Kyllä",
          trigger: "hank-helsinki-question-21"
       }
    ]
  },
  {
    id: "hank-helsinki-question-12",
    message: "Onko kyse alle 60 000 euron hankinnasta?",
    trigger: "hank-helsinki-hank-helsinki-question-12-choices"
  },
  {
    id: "hank-helsinki-hank-helsinki-question-12-choices",
    metadata: {
       parent: "hank-helsinki-question-12",
       detail: {
          en: {
             detail: "",
             title: ""
          },
          fi: {
             detail: "",
             title: "Onko kyse alle 60 000 euron hankinnasta?"
          }
       }
    },
    options: [
       {
          value:23,
          label:"Kyllä",
          trigger: "hank-helsinki-question-13"
       },
       {
          value:24,
          label:"Ei",
          trigger: "hank-helsinki-question-17"
       }
    ]
  },
  {
    id: "hank-helsinki-question-13",
    message: "Minikilpailuta hankinta. Muista, että tarjouspyynnön lähettäminen yhtä useammalle toimittajalle katsotaan kilpailutukseksi. ",
    trigger: "hank-helsinki-question-14"
  },
  {
    id: "hank-helsinki-question-14",
    message: "Voit toteuttaa hankinnan hankintavaltuuksien puitteissa pöytäkirjapäätöksenä. ",
    trigger: "hank-helsinki-toimialat"
  },
  {
    id: "hank-helsinki-hank-helsinki-question-14-choices",
    metadata: {
       parent: "hank-helsinki-question-14",
       detail: {
          en: {
             detail: "",
             title: ""
          },
          fi: {
             detail: "Pöytäkirjapäätöksellä tarkoitetaan....",
             title: "Voit toteuttaa hankinnan hankintavaltuuksien puitteissa pöytäkirjapäätöksenä. "
          }
       }
    },
    options: [
       {
          value:28,
          label:"Kyllä",
          trigger: "hank-helsinki-toimialat"
       }
    ]
  },
  {
    id: "hank-helsinki-question-17",
    message: "Kilpailuta hankinta hankintalain mukaisesti. Päätökseen voi hakea muutosta.",
    trigger: "hank-helsinki-question-18"
  },
  {
    id: "hank-helsinki-question-18",
    message: "Hankinnasta tehdään kirjallinen hankintapäätös hankintavaltuuksien puitteissa.",
    trigger: "hank-helsinki-toimialat"
  },
  {
    id: "hank-helsinki-question-21",
    message: "Onko kyse etusijajärjestyksestä?",
    trigger: "hank-helsinki-hank-helsinki-question-21-choices"
  },
  {
    id: "hank-helsinki-hank-helsinki-question-21-choices",
    metadata: {
       parent: "hank-helsinki-question-21",
       detail: {
          en: {
             detail: "",
             title: ""
          },
          fi: {
             detail: "",
             title: "Onko kyse etusijajärjestyksestä?"
          }
       }
    },
    options: [
       {
          value:40,
          label:"Ei",
          trigger: "hank-helsinki-question-22"
       },
       {
          value:41,
          label:"Kyllä",
          trigger: "hank-helsinki-question-23"
       }
    ]
  },
  {
    id: "hank-helsinki-question-22",
    message: "Onko kyse alle 60 000 euron hankinnasta?",
    trigger: "hank-helsinki-hank-helsinki-question-22-choices"
  },
  {
    id: "hank-helsinki-hank-helsinki-question-22-choices",
    metadata: {
       parent: "hank-helsinki-question-22",
       detail: {
          en: {
             detail: "",
             title: ""
          },
          fi: {
             detail: "",
             title: "Onko kyse alle 60 000 euron hankinnasta?"
          }
       }
    },
    options: [
       {
          value:43,
          label:"Ei",
          trigger: "hank-helsinki-question-24"
       },
       {
          value:44,
          label:"Kyllä",
          trigger: "hank-helsinki-question-27"
       }
    ]
  },
  {
    id: "hank-helsinki-question-23",
    message: "Onko kyse alle 60 000 euron hankinnasta?",
    trigger: "hank-helsinki-hank-helsinki-question-23-choices"
  },
  {
    id: "hank-helsinki-hank-helsinki-question-23-choices",
    metadata: {
       parent: "hank-helsinki-question-23",
       detail: {
          en: {
             detail: "",
             title: ""
          },
          fi: {
             detail: "",
             title: "Onko kyse alle 60 000 euron hankinnasta?"
          }
       }
    },
    options: [
       {
          value:46,
          label:"Ei",
          trigger: "hank-helsinki-question-30"
       },
       {
          value:47,
          label:"Kyllä",
          trigger: "hank-helsinki-question-8"
       }
    ]
  },
  {
    id: "hank-helsinki-question-24",
    message: "Hankinta minikilpailutetaan puitejärjestelyn ehtojen mukaisesti. Kilpailuttaminen voidaan jättää toteuttamatta vain erityistilanteissa, esimerkiksi suorahankintaperusteen käsillä ollessa.",
    trigger: "hank-helsinki-toimialat"
  },
  {
    id: "hank-helsinki-question-27",
    message: "Hankinta voidaan (mini)kilpailuttaa tai hankkia muulla tavoin. Tärkeää on, että asiasta otetaan maininta tarjouspyyntöön/sopimukseen, jos poikkeamismahdollisuus halutaan varata. Kyseessä on tällöin normaali pienhankintaa koskeva päätös. Kaupungin päätöksentekoa ohjeet (ml. pienhankintaohjeet, talousarvion noudattamisohje) ja soveltuva lainsäädäntö (hallintolaki, kuntalaki ym.) on päätöstä tehtäessä otettava huomioon. Päätöksestä tulee sen muodosta riippumatta ilmetä peruste etusijajärjestyksestä poikkeamiseen ja päätökseen voidaan hakea muutosta.",
    trigger: "hank-helsinki-toimialat"
  },
  {
    id: "hank-helsinki-question-30",
    message: "Tilaus tehdään aina etusijajärjestyksen (tai muun ennakolta ilmoitetun valintasäännön tai mekanismin) mukaisesti ensisijaiselta toimittajalta. Jos etusijajärjestyksessä ensisijainen toimittaja ei pysty hoitamaan tilausta, tilaus tehdään etusijajärjestyksessä seuraavalta toimittajalta. Kun hankinta tehdään etusijajärjestyksen (tai vastaavan mekansmin perusteella, kyse on tilauksesta). Tilauspäätökseen muutosta ei voi hakea. ",
    trigger: "hank-helsinki-hank-helsinki-question-30-choices"
  },
  {
    id: "hank-helsinki-hank-helsinki-question-30-choices",
    metadata: {
       parent: "hank-helsinki-question-30",
       detail: {
          en: {
             detail: "",
             title: ""
          },
          fi: {
             detail: "",
             title: "Tilaus tehdään aina etusijajärjestyksen (tai muun ennakolta ilmoitetun valintasäännön tai mekanismin) mukaisesti ensisijaiselta toimittajalta. Jos etusijajärjestyksessä ensisijainen toimittaja ei pysty hoitamaan tilausta, tilaus tehdään etusijajärjestyksessä seuraavalta toimittajalta. Kun hankinta tehdään etusijajärjestyksen (tai vastaavan mekansmin perusteella, kyse on tilauksesta). Tilauspäätökseen muutosta ei voi hakea. "
          }
       }
    },
    options: [
       palaa_alkuun
    ]
  }
]

export default Introduction;
