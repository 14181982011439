const defaultLanguage = 'fi';
let selectedLanguage = null;

export default {
  setLanguage: (language) =>
          selectedLanguage = language || defaultLanguage
  ,
  getLanguage: () => selectedLanguage || defaultLanguage,
  translateStep: (translations) =>  {
    return translations[selectedLanguage || defaultLanguage]
  }
};
