import InternalizationService from './InternalizationService';

const translateGraph = (graph) => {
  const VARIABLES = InternalizationService.translateStep({
    en: {
      yes: 'Yes',
      no: 'No',
      detail: 'Details'
    },
    fi: {
      yes: 'Kyllä',
      no: 'Ei',
      detail: 'Selite'
    }
  })

  let steps = []
  // Map through all the nodes
  graph.nodes.map((node) => {
    let choiceValue = 1
    // Get all edges, which have this node as the source
    const edges = graph.edges.filter((edge) => {
      return edge.source === node.id
    })

    // Create options
    let options = edges.map((edge, i) => {
      return {
        value: choiceValue++,
        label: edge.type === 'yes' ? VARIABLES.yes : VARIABLES.no,
        trigger: edge.target
      }
    })

    let step = {
      id: node.id,
      message: () => InternalizationService.translateStep({fi: node.detail.fi.title, en: node.detail.en.title})
    }

    switch(node.type) {
      case 'link':
        if (node.detail.link) step['trigger'] = node.detail.link
        steps.push(step)
        break;
      case 'answer':
        step['metadata'] = {
          navigation: `nav-${graph.phase}`,
          contact: graph.contact
        }
        step['trigger'] = 'summary'
        steps.push(step)
        break;
      default:
        step['trigger'] = `${node.id}-choices`
        steps.push(step)

        // Add choices
        let choices = {
          id: step.trigger
        }

        // Add details
        if (node.detail.fi.detail) {
          choices['metadata'] = {
            parent: node.id,
            detail: InternalizationService.translateStep({
              fi: node.detail.fi.detail,
              en: node.detail.en.detail
            })
          }
          options.push({ value: choiceValue++, label: VARIABLES.detail, trigger: 'detail' })
        }

        choices['options'] = options
        steps.push(choices)
    }

    return ''
  })

  return steps
}

export { translateGraph }
