import styled from 'styled-components'

const Container = styled.div``
const Header = styled.h3``
const Paragraph = styled.p``
const Button = styled.button`
  display:inline-block;
  padding: 12px;
  border-radius: 22px;
  font-size: 14px;
  background: #2bc732;
  cursor: pointer;
  font-family: Roboto;
  margin: 2px;
  color: #fbfbfb;

  &:hover {
    opacity: 0.7;
  }
`
const Image = styled.img``
const Link = styled.a``
const SpeechContainer = styled(Container)`
  position: absolute;
  right: 70px;
  top: 0px;
  margin: 10px;
  z-index: 1000;
`
const SpeechText = styled.button`
  color: #2bc732;
  margin-top: 7px;
  letter-spacing: 5px;
  cursor: pointer;
  background: none;
  border: none;

  &.disable {
    text-decoration: line-through;
    color: #dddddd;
  }
`
const AnswerContainer = styled(Container)`
  margin-bottom: 2rem;
`
const Key = styled(Container)`
  display: inline-block;
  width: 10rem;
`
const Value = styled(Container)`
  display: inline-block;
`
const DetailContainer = styled(Container)`
  width: 100%;
  background: #dddddd;
  margin: 0 6px 10px 6px;
  padding: 16px;
  box-shadow: rgba(0,0,0,0.15) 0px 1px 2px 0px;
  border-radius: 5px;
`
const CardContainer = styled(Container)`
  display: block;
  height: 200px;
`
const CardImage = styled(Image)`
  float: left;
  max-height: 200px;
  border-radius: 22px;
`
const CardHeader = styled(Header)`
  margin-bottom: 5px;
`
const CardTitle = styled(Paragraph)`
  margin: 5px 0 5px 0;
  font-style: italic;
  font-size: 14px;
`
const IntroContainer = styled(Container)`
  position: relative;
  width: 100%;
  background: #0072C6;
  text-align: center;
  color: white;
  padding-top: 30px;
`
const IntroImage = styled.img`
  position: absolute;
  width: 200px;
  margin-left: -100px;
  z-index: 1;
`
const IntroHeader = styled(Header)`
  position: relative;
  font-family: Rubik;
  font-size: 48px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 4px;
  color: #fdfbff;
  margin-bottom: 5px;
  margin-top: 140px;
  z-index: 2;
  -webkit-animation: glow 1s ease-in-out infinite alternate;
  -moz-animation: glow 1s ease-in-out infinite alternate;
  animation: glow 1s ease-in-out infinite alternate;
`
const IntroLogo = styled(Image)`
  height: 80px;
  float: right;
  @media (max-width: 768px) {
    height: 80px;
  }
  margin-right: 20px;
  margin-bottom: 20px;
`
const SummaryLogo = styled(IntroLogo)`
  margin: -16px -16px 0 0;
`
const IntroSubtitle = styled(Paragraph)`
  font-size: 14px;
  letter-spacing: 5px;
  margin-top: 10px;
  margin-bottom: 30px;
`
const IntroParagraph = styled(Paragraph)`
  padding: 0% 30% 0% 30%;
  @media (max-width: 768px) {
    padding: 0% 10% 0% 10%;
  }
`
const FlagContainer = styled(Container)`
  position: absolute;
  right: 0px;
  top: 0px;
  margin: 10px;
  z-index: 1000;
`

export {
  Button,
  Container,
  CardContainer,
  CardHeader,
  CardTitle,
  CardImage,
  DetailContainer,
  IntroContainer,
  IntroHeader,
  IntroSubtitle,
  IntroParagraph,
  IntroImage,
  IntroLogo,
  Link,
  Header,
  SpeechContainer,
  SpeechText,
  Paragraph,
  AnswerContainer,
  SummaryLogo,
  Key,
  Value,
  FlagContainer
}
