const summaryServiceUrl = 'https://us-central1-ptcservices.cloudfunctions.net/sendSummary';
//const summaryServiceUrl = 'http://localhost:5000/ptcservices/us-central1/sendSummary'; // For local testing

const chatbotStepCache = 'rsc_cache';

const sendSummary = async (address, steps, summary, contact, simulation) =>
        fetch(summaryServiceUrl, {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({address, steps, summary, contact, simulation}),
        }).then(response => response);

const getSteps = () => {
  const steps = JSON.parse(localStorage.getItem(chatbotStepCache));
  // previousSteps contain conversation history without current and previous message.
  // Step may be reference without actual message, filter to avoid undefined values.
  let filtered = steps.previousSteps
          .filter(step => step.message || step.component === 'detail')
          .map(step => {
            return {
              ...step,
              message: step.message || ''
            }
          });

  if (steps.previousStep.message)
    filtered.push(steps.previousStep);

  if (steps.currentStep.message)
    filtered.push(steps.currentStep);

  const result = filtered
          .map((step, index) => {
            let message;

            if (index > 0) {

              // Detail component contains references to previous values - find and replace.
              if (step.component === 'detail') {

                try {
                  const meta = filtered[index - 1].metadata;
                  if (typeof meta === 'string' && meta.startsWith('~')) {
                    // meta will refer to previous value instead of actual value if
                    // same detail component is rendered more than once
                    const referenceId = parseInt(meta.replace(/\D/g, ""));
                    message = referenceId ? steps.previousSteps[referenceId].metadata.detail : '?';
                  } else {
                    message = meta ? meta.detail : '?';
                  }
                } catch (error) {
                  message = '?';
                }

              } else {
                // Massage values in steps may contain references to previous values - find and replace.
                message = step.message
                        .replace('{previousValue}', filtered[index - 1].message);
              }
            } else {
              message = step.message;
            }

            return {
              message: message,
              user: step.user || false
            }

          });

  return JSON.stringify(result);
};

const createSummary = async (address, summary = '-', contact, simulation, ignoreSteps) => {
  if (!address) {
    throw new Error("E-mail is required!");
  }

  try {
    const steps = getSteps();
    await sendSummary(address, ignoreSteps ? null : steps, summary, contact, simulation);
  } catch (err) {
    throw err;
  }
};

export {createSummary};
