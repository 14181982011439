import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Loading } from 'react-simple-chatbot';
import {
  Paragraph,
  Container,
  DetailContainer,
  CardContainer,
  CardHeader,
  CardImage,
  CardTitle,
  Button,
  Link,
  SummaryLogo
} from './styled';

import { createSummary } from '../services/SummaryService';
import contacts from '../contacts'
import logo from '../ptcs_logo2.svg'

class Summary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      result: '',
      continue: true
    };
  }

  componentWillMount() {
    this.setState({ loading: false, result: this.props.previousStep.message, continue: true })
  }

  sendSummary = () => {
    const email = prompt("Anna sähköpostiosoitteesi");
    return email ? createSummary(email, this.state.result, this.props.contactPerson, null) : null;
  };

  render() {
    const { contactPerson } = this.props;
    const { loading, result } = this.state;

    return (
            <DetailContainer>
              <Link href="https://www.ptcs.fi" target="_blank">
                <SummaryLogo src={logo}/>
              </Link>
              <Paragraph>Antamanne vastauksien perusteella: <strong>{loading ? <Loading/> : result}</strong></Paragraph>
              <em>Mikäli haluatte lisätietoja, voitte ottaa asiantuntijaamme yhteyttä.</em>
              <CardContainer>
                <CardImage src={contactPerson.avatarURL}/>
                <Container>
                  <CardHeader>{contactPerson.name}</CardHeader>
                  <CardTitle>{contactPerson.title}</CardTitle>
                  <Paragraph>{contactPerson.telephone}</Paragraph>
                  <Paragraph>{contactPerson.email}</Paragraph>
                  <Paragraph><strong>Erityisosaaminen:</strong>&nbsp;{contactPerson.areaOfExpertise}</Paragraph>
                </Container>
              </CardContainer>
              {this.state.continue ?
                      <Container style={{float: 'right', marginTop: '10px'}}>
                        <Button onClick={this.sendSummary}>Lähetä keskustelu sähköpostiini</Button>
                        <Button onClick={() => {
                          this.props.triggerNextStep({
                            value: 1,
                            trigger: this.props.previousStep.metadata.navigation
                          });
                          this.setState({continue: false})
                        }}>Jatka keskustelua
                        </Button>
                      </Container> : null}
            </DetailContainer>
    );
  }
}

Summary.propTypes = {
  previousStep: PropTypes.object,
  steps: PropTypes.object,
  triggerNextStep: PropTypes.func,
  contactPerson: PropTypes.shape({
    avatarUrl: PropTypes.string,
    title: PropTypes.string,
    telephone: PropTypes.string,
    email: PropTypes.string,
    areaOfExpertise: PropTypes.string,
  }),
};

Summary.defaultProps = {
  steps: undefined,
  triggerNextStep: undefined,
  previousStep: {},
  contactPerson: contacts.jussipyykkonen
};

export default Summary;
