import React, { Component } from 'react';
import { IntroContainer, IntroHeader, IntroImage, IntroSubtitle, IntroParagraph, IntroLogo, Link } from './styled';
import avatar from '../hank_intro.svg';
import logo from '../helsinki.png';

class Intro extends Component {
  render() {
    return (
      <IntroContainer>
        <IntroImage src={avatar}/>
        <IntroHeader>Hank Helsinki</IntroHeader>
        <IntroSubtitle>HANKINTABOTTI</IntroSubtitle>
        <IntroParagraph>Hei Olen Hank Helsinki. Autan sinua hankintoihin liittyvissä kysymyksissä (tilaamiseen liittyvät kysymykset voit ratkaista täällä:....)</IntroParagraph>
        <Link href="https://www.hel.fi" target="_blank">
          <IntroLogo src={logo}/>
        </Link>
      </IntroContainer>
    );
  }
}

export default Intro
