const contacts = {
  jussipyykkonen: {
    name: 'Jussi Pyykkönen',
    avatarURL: 'https://ptcs.fi/wp-content/uploads/2017/06/3108-ptcservices_0141-lores-180x240.jpg',
    title: 'Asiantuntija, VTM',
    telephone: '+358 44 023 9149',
    email: 'jussi.pyykkonen@ptcs.fi',
    areaOfExpertise: 'Innovatiiviset hankinnat, vaikuttavuusperusteiset hankinnat'
  },
  pilvitakala: {
    name: 'Pilvi Takala',
    avatarURL: 'https://ptcs.fi/wp-content/uploads/2017/06/1708-ptc-pilvi_0217-180x240.jpg',
    title: 'Asiantuntija, OTK, osakas, hallituksen puheenjohtaja',
    telephone: '+358 40 524 0522',
    email: 'pilvi.takala@ptcs.fi',
    areaOfExpertise: 'KV-asiat, hankintojen johtaminen'
  },
  tapiolahtinen: {
    name: 'Tapio Lahtinen',
    avatarURL: 'https://ptcs.fi/wp-content/uploads/2017/06/1708-ptc-tapio_0406-180x240.jpg',
    title: 'Asiantuntija, varatuomari, osakas',
    telephone: '+358 50 490 4424',
    email: 'tapio.lahtinen@ptcs.fi',
    areaOfExpertise: 'ICT-hankinnat, sopimusoikeus, tietosuoja'
  },
  sarataivainen: {
    name: 'Sara Taivainen',
    avatarURL: 'https://ptcs.fi//wp-content/uploads/2019/03/Sara_Taivainen_kasvo-180x240.jpg',
    title: 'Oikeustieteen notaari',
    telephone: '+358 50 571 4757',
    email: 'sara.taivainen@ptcs.fi',
    areaOfExpertise: 'Harjoittelija'
  },
  juholehtoviita: {
    name: 'Juho Lehtoviita',
    avatarURL: 'https://ptcs.fi/wp-content/uploads/2017/06/1508-ptc-juho_0125-180x240.jpg',
    title: 'Asiantuntija, YTM',
    telephone: '+358 50 322 4498',
    email: 'juho.lehtoviita@ptcs.fi',
    areaOfExpertise: 'Julkiset, KV-hankinnat'
  }
};

export default contacts;
