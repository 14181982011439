import React from 'react';
import PropTypes from 'prop-types';
import ReactFlagsSelect from 'react-flags-select';
import 'react-flags-select/css/react-flags-select.css';
import { FlagContainer } from './styled';


const LanguageSelector = ({onSelect, defaultLanguage}) => {
  return (
    <FlagContainer>
      <ReactFlagsSelect
              defaultCountry={defaultLanguage ? languageToCountry(defaultLanguage) : 'FI'}
              showSelectedLabel={false}
              showOptionLabel={false}
              countries={["FI", "GB"]}
              onSelect={onSelect}/>
    </FlagContainer>
  );
};

export const languageToCountry = language => language==='en' ? 'GB' : language.toUpperCase();
export const countryToLanguage = countryCode => countryCode === 'GB' ? 'en' : countryCode.toLocaleLowerCase();

LanguageSelector.propTypes = {
  onSelect: PropTypes.func,
  defaultLanguage: PropTypes.string,
};

export default LanguageSelector;
