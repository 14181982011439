import React, {Component} from 'react';

import ChatBot from 'react-simple-chatbot';
import queryString from 'query-string';
import { ThemeProvider } from 'styled-components';
import { Provider } from "redux-zero/react";

import store from "./store/store";
import { decodePreview } from './services/PreviewService';
import InternalizationService from './services/InternalizationService';
import LanguageSelector, { countryToLanguage } from './components/languageSelector';
import SpeechSelector from './components/speechSelector';
import { translateGraph } from './services/GraphService';
import avatar from './hank_icon.svg';
import { Steps, getChoices } from './steps/steps'

const styling = {
  avatarStyle: {
    height: '36px'
  },
  botAvatar: avatar,
  bubbleOptionStyle: {
    background: '#9FC9EB',
    color: '#fbfbfb'
  },
  contentStyle: {
    marginTop: 0,
    paddingTop: 0
  },
  customStyle: {
    background: '#ededed',
    justifyContent: 'left',
    padding: 0,
    margin: 0,
    boxShadow: '0px 0px 0px 0px'
  },
  hideUserAvatar: true,
  theme: {
    background: '#ededed',
    fontFamily: 'Roboto',
    headerBgColor: '#1f2123',
    headerFontColor: '#fff',
    headerFontSize: '15px',
    botBubbleColor: '#fbfbfb',
    botFontColor: '#3e3e3e',
    userBubbleColor: '#3e3e3e',
    userFontColor: '#ededed',
  }
}

const botOptions = {
  width: `${window.innerWidth}px`,
  height: `${window.innerHeight}px`,
  placeholder: 'Kirjoita viesti ...',
  headerTitle: ''
}

class Hank extends Component {

  state = {
    steps: null,
    loading: true,
    speech: null
  };

  // Check if preview content exists in URL query param and combine internal & db originated steps
  setSteps = async () => {
    const previewContent = queryString.parse(window.location.search).preview;
    const internalContent = Steps;

    const steps = previewContent ?
            translateGraph(decodePreview(previewContent)).concat(internalContent)
            : internalContent;
    this.setState({steps, loading: false});
  };

  // Check if language exists in URL query param
  setLanguage = () => InternalizationService.setLanguage(queryString.parse(window.location.search).language);

  refreshView = ({ speech }) => {
    const params = queryString.parse(window.location.search);
    params.language = InternalizationService.getLanguage();
    params.speech = speech === true ? !this.state.speech : this.state.speech
    window.location.href = `//${window.location.host}${window.location.pathname}?${Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&')}`;
  };

  onSelectLanguage = (countryCode) => {
    const language = countryToLanguage(countryCode);
    InternalizationService.setLanguage(language);
    this.refreshView({ speech: false });
  };

  componentWillMount() {
    localStorage.removeItem('rsc_cache');

    this.setSteps();
    this.setLanguage();
  }

  componentDidMount() {
    this.handleEnd = this.handleEnd.bind(this);
  }

  handleEnd({ renderedSteps, steps, values }) {
    console.log(renderedSteps, steps, values);
    // The renderedSteps only reachable, when the end-flag is set to true in a step.
    // However, this does not allow to continue the conversation.

    // console.log(values);
    // alert(`Chat handleEnd callback! Number: ${values[0]}`);
  }

  render() {
    return (
      this.state.loading ? <React.Fragment/> :
        <Provider store={store}>
          <ThemeProvider theme={styling.theme}>
            <React.Fragment>
              <LanguageSelector defaultLanguage={InternalizationService.getLanguage()}
                                onSelect={this.onSelectLanguage}/>
              <ChatBot
                speechSynthesis={{enable: false, lang: InternalizationService.getLanguage()}}
                handleEnd={this.handleEnd}
                cache={true}
                width={botOptions.width}
                height={botOptions.height}
                placeholder={botOptions.placeholder}
                headerTitle={botOptions.headerTitle}
                steps={this.state.steps}
                bubbleOptionStyle={styling.bubbleOptionStyle}
                hideUserAvatar={styling.hideUserAvatar}
                botAvatar={styling.botAvatar}
                avatarStyle={styling.avatarStyle}
                customStyle={styling.customStyle}
                contentStyle={styling.contentStyle}
              />
            </React.Fragment>
          </ThemeProvider>
        </Provider>
    );
  }
}

export default Hank;
