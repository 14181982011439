import React from 'react'
import Detail from '../components/detail'
import Summary from '../components/summary'
import { secureStep } from '../services/PasswordService';

import Introduction from './introduction'

const Steps = Introduction.concat([
  {
    id: 'detail',
    component: <Detail />,
    waitAction: true,
    trigger: 'hank-helsinki-question-1',
  },
  {
    id: 'summary',
    component: <Summary />,
    waitAction: true
  }
])

const getChoices = (treeArr) => {
  let choices = []
  let phase = undefined

  let options = treeArr.map((tree, i) => {
    if (!phase) phase = tree['phase']
    return { value: i+1, label: tree['treeName'], trigger: `${tree['uuid']}-question-1` }
  })
  options.push({ value: options.length+1, label: 'Siirry hankinnan eri vaiheisiin', trigger: 'nav-phase' })

  let choice = {
    id: `nav-${phase}`,
    trigger: `nav-${phase}-choices`
  }

  switch (phase) {
    case 'planning':
      choice['message'] = 'Suunnitteluvaiheessa liittyy seuraavat kysymykset'
      break
    case 'bidding':
      choice['message'] = 'Tarjouspyynnön viimeistelyyn liittyy seuraavat kysymykset'
      break
    case 'purchase':
      choice['message'] = 'Hankintapäätöksen laatimiseen liittyy seuraavat kysymykset'
      break
    case 'contract':
      choice['message'] = 'Sopimuskauteen liittyy seuraavat kysymykset'
      break
    default:
      choice['message'] = 'Tämä on virhe, ota yhteyttä palveluntuottajaan, jos näet tämän viestin.'
  }

  choices.push(choice)
  choices.push({
    id: `nav-${phase}-choices`,
    options: options
  })

  return choices
}

export { Steps, getChoices };
