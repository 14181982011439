import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Loading } from 'react-simple-chatbot';
import { DetailContainer, Paragraph } from './styled'
import InternalizationService from '../services/InternalizationService';

class Detail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      result: ''
    };
  }

  componentWillMount() {
    let result = InternalizationService.translateStep({fi: this.props.previousStep.metadata.detail.fi.detail, en: this.props.previousStep.metadata.detail.en.detail})
    this.setState({ loading: false, result})
    this.props.triggerNextStep({ value: 1, trigger: this.props.previousStep.metadata.parent })
  }

  render() {
    const { loading, result } = this.state;

    return (
      <DetailContainer>
        <Paragraph>{ loading ? <Loading /> : result }</Paragraph>
      </DetailContainer>
    );
  }
}

Detail.propTypes = {
  steps: PropTypes.object,
  triggerNextStep: PropTypes.func,
};

Detail.defaultProps = {
  steps: undefined,
  triggerNextStep: undefined,
};

export default Detail
